import { useState } from "react";
import axios from "axios";
import axiosServices from "../utils/axiosServices";

export const useCodebaseUpload = () => {
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelected = (file) => {
    setUploadedFileName(file.name);
    setSelectedFile(file);
  };

  const handleFileDelete = () => {
    setUploadedFileName("");
    setSelectedFile(null);
  };

  const downloadGithubRepo = async (owner, repo, githubToken, projectId) => {
    try {
      // Get the zip buffer from GitHub
      const response = await axios({
        method: "get",
        url: `https://api.github.com/repos/${owner}/${repo}/zipball`,
        responseType: "blob",
        headers: {
          Accept: "application/vnd.github+json",
          Authorization: `Bearer ${githubToken}`,
          "X-GitHub-Api-Version": "2022-11-28",
        },
      });

      // Create file from the blob
      const file = new File([response.data], `${repo}.zip`, {
        type: "application/zip",
      });

      // Create FormData and upload immediately
      const formData = new FormData();
      formData.append("project_id", projectId);
      formData.append("file", file);

      // Upload to backend
      await axiosServices.post("/projects/uploadcodebase", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return file;
    } catch (error) {
      console.error("Error downloading repository:", error);
      throw error;
    }
  };

  return {
    uploadedFileName,
    selectedFile,
    handleFileSelected,
    handleFileDelete,
    downloadGithubRepo,
  };
};
