import { Add } from "@mui/icons-material";
import {
  Typography,
  Button,
  Box,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import axiosServices from "../utils/axiosServices";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useConfirmationPopup } from "../components/openconfrmationmodel";

function AddCollaborator({ project }) {
  const { openConfirmationPopup, ConfirmationModal } = useConfirmationPopup();
  const [showAddInput, setShowAddInput] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [collaborators, setCollaborators] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const validateEmail = (email) => {
    if (!email) {
      return "Email is required";
    }

    const trimmedEmail = email.trim();
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(trimmedEmail)) {
      return "Please enter a valid email address";
    }

    if (collaborators.some((c) => c.email === trimmedEmail)) {
      return "Looks like they're already on board with this project!";
    }
    return "";
  };

  const fetchCollaborators = async () => {
    setIsLoading(true);
    try {
      const payload = {
        project_id: project.project_id,
      };
      console.log("Fetching collaborators with payload:", payload);

      const response = await axiosServices.post(
        "/projects/get-collaborators",
        payload
      );

      console.log("Get collaborators full response:", response);
      console.log("Get collaborators data:", response.data);

      if (response.data && Array.isArray(response.data.users)) {
        const validCollaborators = response.data.users.filter(
          (user) =>
            typeof user.email === "string" &&
            user.email.includes("@") &&
            typeof user.role === "string"
        );

        console.log("Setting collaborators to:", validCollaborators);
        setCollaborators(validCollaborators);
      } else {
        console.error("Invalid collaborators data:", response.data);
        setCollaborators([]);
      }
    } catch (error) {
      console.error("Error fetching collaborators:", error);
      setCollaborators([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (project.project_id) {
      console.log("Initial fetch of collaborators");
      fetchCollaborators();
    }
  }, [project.project_id]);

  const handleDeleteCollaborator = async (emailToDelete) => {
    openConfirmationPopup({
      title: "Remove Collaborator",
      message: `Are you sure you want to remove ${emailToDelete}?`,
      confirmButtonLabel: "Remove",
      cancelButtonLabel: "Cancel",
      onConfirm: async () => {
        try {
          const response = await axiosServices.post(
            `/projects/remove-collaborator`,
            {
              project_id: project.project_id,
              collaborator_email: emailToDelete,
            }
          );

          if (response.status === 200) {
            setCollaborators(
              collaborators.filter((c) => c.email !== emailToDelete)
            );
            await fetchCollaborators();
            window.dispatchEvent(
              new CustomEvent("showSnackbar", {
                detail: { message: "Collaborator removed successfully" },
              })
            );
          }
        } catch (error) {
          console.error("Error removing collaborator:", error);
          window.dispatchEvent(
            new CustomEvent("showSnackbar", {
              detail: { message: "Failed to remove collaborator" },
            })
          );
        }
      },
    });
  };

  const handleAddCollaborator = async () => {
    try {
      const trimmedEmail = newEmail.trim();
      const error = validateEmail(trimmedEmail);
      if (error) {
        setEmailError(error);
        return;
      }

      const response = await axiosServices.post(`/projects/add-collaborator`, {
        project_id: project.project_id,
        email: trimmedEmail,
        role: "user",
      });

      if (response.status === 200) {
        setNewEmail("");
        setShowAddInput(false);
        setEmailError("");
        await fetchCollaborators();
        window.dispatchEvent(
          new CustomEvent("showSnackbar", {
            detail: { message: "Collaborator added successfully" },
          })
        );
      } else {
        setEmailError("Failed to add collaborator");
        window.dispatchEvent(
          new CustomEvent("showSnackbar", {
            detail: { message: "Failed to add collaborator" },
          })
        );
      }
    } catch (error) {
      console.error("Error adding collaborator:", error);
      setEmailError("Failed to add collaborator");
      window.dispatchEvent(
        new CustomEvent("showSnackbar", {
          detail: { message: "Failed to add collaborator" },
        })
      );
    }
  };

  const renderCollaborators = () => {
    if (!Array.isArray(collaborators)) {
      console.error("Collaborators is not an array:", collaborators);
      return null;
    }

    const userCollaborators = collaborators
      .filter((collaborator) => collaborator.role.toLowerCase() === "user")
      .map((collaborator, index) => (
        <tr key={collaborator.email || index}>
          <td
            style={{
              padding: "4px 6px",
              borderBottom: "1px solid #e0e0e0",
              borderRight: "1px solid #e0e0e0",
              fontSize: "0.875rem",
            }}
          >
            {index + 1}
          </td>
          <td
            style={{
              padding: "4px 6px",
              borderBottom: "1px solid #e0e0e0",
              borderRight: "1px solid #e0e0e0",
              fontSize: "0.875rem",
            }}
          >
            {collaborator.email}
          </td>
          <td
            style={{
              padding: "4px 6px",
              borderBottom: "1px solid #e0e0e0",
              textAlign: "center",
              fontSize: "0.875rem",
            }}
          >
            <IconButton
              onClick={() => handleDeleteCollaborator(collaborator.email)}
              size="small"
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </td>
        </tr>
      ));

    if (isLoading) {
      return (
        <tr>
          <td colSpan={3} style={{ textAlign: "center", padding: "16px" }}>
            <CircularProgress size={24} />
          </td>
        </tr>
      );
    }

    if (userCollaborators.length === 0) {
      return (
        <tr>
          <td colSpan={3} style={{ textAlign: "center", padding: "16px" }}>
            No collaborators found
          </td>
        </tr>
      );
    }

    return userCollaborators;
  };

  return (
    <>
      <Box sx={{ width: "600px", maxWidth: "90vw", position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
            pt: 2,
            pb: 1,
            mx: 1,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "1.1rem",
              color: "#525252",
              fontWeight: 600,
            }}
          >
            Collaborators
          </Typography>
        </Box>

        <Box sx={{ p: 3, pt: 0 }}>
          <Box sx={{ mb: 8 }}>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                border: "1px solid #e0e0e0",
                backgroundColor: "white",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: "4px 6px",
                      textAlign: "left",
                      width: "10%",
                      borderBottom: "1px solid #e0e0e0",
                      borderRight: "1px solid #e0e0e0",
                      backgroundColor: "#f5f5f5",
                      fontSize: "0.875rem",
                    }}
                  >
                    No.
                  </th>
                  <th
                    style={{
                      padding: "4px 6px",
                      textAlign: "left",
                      width: "70%",
                      borderBottom: "1px solid #e0e0e0",
                      borderRight: "1px solid #e0e0e0",
                      backgroundColor: "#f5f5f5",
                      fontSize: "0.875rem",
                    }}
                  >
                    Email
                  </th>
                  <th
                    style={{
                      padding: "4px 6px",
                      textAlign: "center",
                      width: "20%",
                      borderBottom: "1px solid #e0e0e0",
                      backgroundColor: "#f5f5f5",
                      fontSize: "0.875rem",
                    }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>{renderCollaborators()}</tbody>
            </table>
            {showAddInput && (
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  alignItems: "flex-start",
                  gap: 1,
                }}
              >
                <TextField
                  size="small"
                  value={newEmail}
                  onChange={(e) => {
                    setNewEmail(e.target.value);
                    setEmailError("");
                  }}
                  placeholder="Enter email"
                  error={!!emailError}
                  helperText={emailError}
                  fullWidth
                  sx={{
                    "& .MuiFormHelperText-root": {
                      marginLeft: "0",
                      fontSize: "0.75rem",
                    },
                  }}
                />
                <Box sx={{ display: "flex", gap: 1, minWidth: "fit-content" }}>
                  <Button
                    variant="contained"
                    onClick={handleAddCollaborator}
                    disabled={!newEmail || !!emailError}
                  >
                    Add
                  </Button>
                  <Button
                    onClick={() => {
                      setShowAddInput(false);
                      setNewEmail("");
                      setEmailError("");
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            position: "absolute",
            bottom: 20,
            right: 24,
            width: "calc(100% - 48px)",
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "white",
          }}
        >
          <Button
            variant="outlined"
            size="small"
            startIcon={<Add />}
            onClick={() => setShowAddInput(true)}
            sx={{ textTransform: "none" }}
          >
            Add Collaborator
          </Button>
        </Box>
      </Box>
      {ConfirmationModal}
    </>
  );
}

export default AddCollaborator;
