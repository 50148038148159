import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../context/AuthContext";
import githubService from "../services/githubService";
import axiosServices from "../utils/axiosServices";

export const useGithub = () => {
  const [isGithubConnected, setIsGithubConnected] = useState(false);
  const [repositories, setRepositories] = useState([]);
  const [selectedRepo, setSelectedRepo] = useState("");
  const { user } = useAuth();

  const fetchRepositories = useCallback(async () => {
    if (!user?.user?.email) return;

    try {
      const repos = await githubService.fetchRepositories(user.user.email);
      setRepositories(repos);
    } catch (error) {
      console.error("Error fetching repositories:", error);
    }
  }, [user?.user?.email]);

  const validateGithubConnection = useCallback(async () => {
    if (!user?.user?.email) return false;

    try {
      const { isConnected } = await githubService.validateConnection(
        user.user.email
      );
      setIsGithubConnected(isConnected);

      if (isConnected) {
        await fetchRepositories();
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error validating GitHub connection:", error);
      setIsGithubConnected(false);
      return false;
    }
  }, [user?.user?.email, fetchRepositories]);

  const handleGithubConnect = async () => {
    const isValid = await validateGithubConnection();
    if (!isValid) {
      githubService.initiateConnection();
    }
  };

  const handleGithubCodeExchange = useCallback(
    async (code) => {
      if (!user?.user?.email || !code) return false;

      try {
        const response = await axiosServices.post(
          "/users/github/exchange-code",
          {
            code,
            email: user.user.email,
          }
        );

        if (response.data.token) {
          setIsGithubConnected(true);
          await fetchRepositories();
          return true;
        }
        return false;
      } catch (error) {
        console.error("Error exchanging GitHub code:", error);
        return false;
      }
    },
    [user?.user?.email, fetchRepositories]
  );

  useEffect(() => {
    validateGithubConnection();
  }, [validateGithubConnection]);

  return {
    isGithubConnected,
    setIsGithubConnected,
    repositories,
    selectedRepo,
    setSelectedRepo,
    handleGithubConnect,
    handleGithubCodeExchange,
    fetchRepositories,
    validateGithubConnection,
  };
};
