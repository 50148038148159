import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import dragImg from "../../assets/images/dragAndDrop.png";

const DragDropBox = styled(Box)(({ isDragging }) => ({
  border: "0.5px dashed #58A942",
  padding: "50px",
  textAlign: "center",
  cursor: "pointer",
  backgroundColor: isDragging ? "#e3f2fd" : "#fafafa",
  transition: "background-color 0.2s",
}));

const DragAndDrop = ({ onFileSelected, disabled }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState("");

  const isZipFile = (file) => {
    return (
      file.type === "application/zip" ||
      file.type === "application/x-zip-compressed" ||
      file.name.toLowerCase().endsWith(".zip")
    );
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    if (!disabled) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    setError("");

    if (!disabled) {
      const file = e.dataTransfer.files[0];
      if (file) {
        if (isZipFile(file)) {
          onFileSelected([file]);
        } else {
          setError("Only ZIP files are supported");
        }
      }
    }
  };

  const handleFileInputChange = (e) => {
    setError("");
    if (!disabled) {
      const file = e.target.files[0];
      if (file) {
        if (isZipFile(file)) {
          onFileSelected([file]);
        } else {
          setError("Only ZIP files are supported");
          e.target.value = ""; // Clear the input
        }
      }
    }
  };

  return (
    <Box>
      <DragDropBox
        isDragging={isDragging}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <Box sx={{ mb: 2 }}>
          <img src={dragImg} alt="dragImg" />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography
              variant="body1"
              sx={{ mr: 1, color: "#525252", fontSize: "13px" }}
            >
              Drag and drop or
            </Typography>
            <input
              type="file"
              style={{ display: "none" }}
              accept=".zip"
              onChange={handleFileInputChange}
              id="fileInput"
              disabled={disabled}
            />
            <label htmlFor="fileInput">
              <Typography
                variant="body2"
                sx={{
                  cursor: disabled ? "default" : "pointer",
                  color: "#525252",
                  textDecoration: "underline",
                  fontSize: "13px",
                  opacity: disabled ? 0.5 : 1,
                }}
              >
                Choose file
              </Typography>
            </label>
          </Box>
          <Typography
            variant="caption"
            sx={{ color: "#666", fontSize: "12px" }}
          >
            Only ZIP files are supported
          </Typography>
          {error && (
            <Typography
              variant="caption"
              sx={{ color: "error.main", fontSize: "12px", mt: 1 }}
            >
              {error}
            </Typography>
          )}
        </Box>
      </DragDropBox>
    </Box>
  );
};

export default DragAndDrop;
