import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import axiosServices from "../../utils/axiosServices";

import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { Formik, Form } from "formik";
import projectValidationSchema from "../../Validations/Projectvalidation";
import UploadCodeBase from "../../components/UploadCodeBase";
import { useGithub } from "../../hooks/useGithub";
import { useCodebaseUpload } from "../../hooks/useCodebaseUpload";

function AddProject({ onSuccess, onFailure, setIsModalOpen, getAllProjects }) {
  const [formData, setFormData] = useState({
    projectName: "",
    description: "",
    file: null,
    collaborators: [{ email: "", role: "" }],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showDragAndDrop, setShowDragAndDrop] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const {
    isGithubConnected,
    repositories,
    selectedRepo,
    setSelectedRepo,
    handleGithubConnect,
    handleGithubCodeExchange,
    fetchRepositories,
    setIsGithubConnected,
  } = useGithub();
  const { selectedFile, handleFileSelected, handleFileDelete, uploadCodebase } =
    useCodebaseUpload();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      const storedProjectId = sessionStorage.getItem("addProjectId");
      const storedShowDragAndDrop = sessionStorage.getItem(
        "addProjectShowDragAndDrop"
      );

      if (storedProjectId && storedShowDragAndDrop) {
        setProjectId(storedProjectId);
        setShowDragAndDrop(true);
      }

      handleGithubCodeExchange(code).then((success) => {
        if (success) {
          setIsGithubConnected(true);
          fetchRepositories();
        }
      });
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [handleGithubCodeExchange, setIsGithubConnected, fetchRepositories]);

  const handleCollaboratorChange = (index, value) => {
    const newCollaborators = [...formData.collaborators];
    newCollaborators[index].email = value;
    setFormData({ ...formData, collaborators: newCollaborators });
  };

  const addCollaborator = () => {
    setFormData((prevData) => ({
      ...prevData,
      collaborators: [...prevData.collaborators, { email: "", role: "" }],
    }));
  };

  const deleteCollaborator = (index) => {
    const newCollaborators = formData.collaborators.filter(
      (_, i) => i !== index
    );
    setFormData({ ...formData, collaborators: newCollaborators });
  };

  const handleLater = (closeAddProjectModal) => {
    sessionStorage.removeItem("addProjectId");
    sessionStorage.removeItem("addProjectShowDragAndDrop");
    setShowDragAndDrop(false);
    closeAddProjectModal();
    setIsModalOpen(false);
  };

  const handleSubmit = async (values) => {
    console.log("Form submitted with values:", values);

    const newErrors = {
      projectName: values.projectName === "",
      description: values.description === "",
    };

    if (!Object.values(newErrors).some(Boolean)) {
      console.log("No validation errors, proceeding to API call");
      const input = {
        project_name: values.projectName,
        project_description: values.description,
        collaborators: values.collaborators.map((collab) => ({
          email: collab.email,
          role: "user",
        })),
      };
      console.log("input", input);
      try {
        setIsLoading(true);
        const response = await axiosServices.post(
          "/projects/initialize",
          input,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status >= 200 && response.status < 300) {
          if (response.data?.message === "Project initialized successfully") {
            const id = response.data.project_id;
            setProjectId(id);
            setShowDragAndDrop(true);
            onSuccess({ project_id: id });
          } else {
            console.error("Unexpected API response:", response.data);
            onFailure();
          }
        } else {
          console.error("API returned unexpected status:", response.status);
          onFailure();
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        onFailure();
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log("Validation errors present, not proceeding");
    }
  };

  const handleInitializeProject = async () => {
    if (!projectId) return;
    setIsLoading(true);

    try {
      if (selectedRepo) {
        const [, , , owner, repo] = selectedRepo.split("/");
        await uploadCodebase(projectId, selectedFile, true, {
          owner,
          repo,
          repoUrl: selectedRepo,
        });
      } else if (selectedFile) {
        await uploadCodebase(projectId, selectedFile);
      }

      // Clean up sessionStorage
      sessionStorage.removeItem("addProjectId");
      sessionStorage.removeItem("addProjectShowDragAndDrop");

      // Reset states and close modals
      setShowDragAndDrop(false);
      handleFileDelete();

      // Force close all modals
      setIsModalOpen(false);

      // Small delay to ensure state updates are processed
      setTimeout(() => {
        // Trigger success callback and refresh projects
        onSuccess();
        if (getAllProjects) {
          getAllProjects();
        }
      }, 100);
    } catch (error) {
      console.error("Error in handleInitializeProject:", error);
      onFailure(error.message || "An error occurred during upload");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        <Box mb={3}>
          <Typography
            variant="h6"
            component="h1"
            align="left"
            gutterBottom
            sx={{ fontWeight: "bold", color: "#525252" }}
          >
            Initialize Codebase
          </Typography>
          <Typography
            variant="subtitle1"
            align="left"
            sx={{ ml: 0.3, color: "#525252" }}
          >
            Fill the following information to get started
          </Typography>
        </Box>
        <Formik
          initialValues={{
            projectName: "",
            description: "",
            collaborators: [{ email: "" }],
            file: null,
          }}
          validationSchema={projectValidationSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleChange,
            setFieldValue,
            setFieldTouched,
            errors,
            touched,
          }) => (
            <Form>
              <TextField
                sx={{
                  "& .MuiFormLabel-asterisk": {
                    color: "red",
                  },
                }}
                label="Project Name"
                name="projectName"
                fullWidth
                onChange={handleChange}
                onBlur={() => setFieldTouched("projectName")}
                error={touched.projectName && Boolean(errors.projectName)}
                helperText={touched.projectName && errors.projectName}
                required
              />
              <TextField
                sx={{
                  "& .MuiFormLabel-asterisk": {
                    color: "red",
                  },
                }}
                label="Project Description"
                name="description"
                fullWidth
                margin="normal"
                multiline
                rows={3}
                onChange={handleChange}
                onBlur={() => setFieldTouched("description")}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
                required
              />
              <Typography variant="subtitle1" align="left" sx={{ mt: 2 }}>
                Add Collaborators
              </Typography>
              <Box display="flex" flexDirection="column" mb={2}>
                {formData.collaborators.map((collaborator, index) => (
                  <Box key={index} display="flex" alignItems="center" mb={1}>
                    <TextField
                      sx={{
                        "& .MuiFormLabel-asterisk": {
                          color: "red",
                        },
                        mr: 1,
                        // width: "60%",
                      }}
                      label="Email"
                      name={`collaborators[${index}].email`}
                      value={collaborator.email}
                      onChange={(e) => {
                        const value = e.target.value;
                        handleCollaboratorChange(index, value);
                        setFieldValue(`collaborators[${index}].email`, value);
                      }}
                      // onBlur={() =>
                      //   setFieldTouched(`collaborators[${index}].email`)
                      // }
                      // error={
                      //   touched.collaborators &&
                      //   Boolean(errors.collaborators?.[index]?.email)
                      // }
                      // helperText={
                      //   touched.collaborators &&
                      //   errors.collaborators?.[index]?.email
                      // }
                      fullWidth
                    />
                    {/* <TextField
                      sx={{
                        mr: 1,
                        width: "30%",
                      }}
                      disabled
                      label="Role"
                      value={collaborator.role || ""}
                      onChange={(e) =>
                        handleCollaboratorRoleChange(index, e.target.value)
                      }
                      fullWidth
                    /> */}
                    <IconButton
                      onClick={() => deleteCollaborator(index)}
                      color="secondary"
                      size="small"
                    >
                      <HighlightOffOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Box>
              <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={addCollaborator}
                  startIcon={<AddCircleOutlinedIcon />}
                  sx={{ textTransform: "none" }}
                >
                  Add Collaborator
                </Button>
              </Box>
              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  align="center"
                  sx={{ px: 5, textTransform: "none" }}
                >
                  Create Project
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Container>

      <UploadCodeBase
        showDragAndDrop={showDragAndDrop}
        handleLater={handleLater}
        setIsModalOpen={setIsModalOpen}
        setShowUploadModal={setShowDragAndDrop}
        isLoading={isLoading}
        isGithubConnected={isGithubConnected}
        handleGithubConnect={handleGithubConnect}
        repositories={repositories}
        selectedRepo={selectedRepo}
        setSelectedRepo={setSelectedRepo}
        uploadedFileName={uploadedFileName}
        setUploadedFileName={setUploadedFileName}
        handleFileDelete={handleFileDelete}
        handleFileSelected={handleFileSelected}
        handleInitializeProject={handleInitializeProject}
        selectedProjectId={projectId}
        getAllProjects={getAllProjects}
      />
    </>
  );
}

export default AddProject;
