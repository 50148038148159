import { useState } from "react";
import { Box, Typography, Button, Modal } from "@mui/material";

export const useConfirmationPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmCallback, setConfirmCallback] = useState(null);
  const [cancelCallback, setCancelCallback] = useState(null);
  const [modalProps, setModalProps] = useState({
    title: "",
    message: "",
    confirmButtonLabel: "OK",
    cancelButtonLabel: "Cancel",
  });

  const openConfirmationPopup = ({
    title,
    message,
    confirmButtonLabel = "OK",
    cancelButtonLabel = "Cancel",
    onConfirm,
    onCancel,
  }) => {
    setConfirmCallback(() => onConfirm);
    setCancelCallback(() => onCancel);
    setModalProps({
      title: title || "Are you sure?",
      message: message || "Do you want to proceed?",
      confirmButtonLabel,
      cancelButtonLabel,
    });
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    if (confirmCallback) confirmCallback();
    closePopup();
  };

  const handleCancel = () => {
    if (cancelCallback) cancelCallback();
    closePopup();
  };

  const ConfirmationModal = (
    <Modal open={isOpen} onClose={closePopup}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          padding: 2,
          borderRadius: 2,
          boxShadow: 24,
          minWidth: 450,
          textAlign: "left",
          minHeight: 200,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            borderBottom: "1px solid lightgray",
            paddingBottom: 1,
            marginBottom: 2,
          }}
        >
          {modalProps.title}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {modalProps.message}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button onClick={handleCancel} variant="outlined" color="primary">
            {modalProps.cancelButtonLabel}
          </Button>
          <Button onClick={handleConfirm} variant="contained" color="error">
            {modalProps.confirmButtonLabel}
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  return {
    openConfirmationPopup,
    ConfirmationModal,
  };
};
