import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

export default function BasicModal({
  onClose,
  isOpen,
  headerText,
  content,
  width,
  height = null,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: "10px",
    width: width,
    height: height ? height : "auto",
  };
  const handleClose = (event, reason) => {
    console.log("BasicModal handleClose called", { reason });
    // Prevent closing on backdrop click if we're in the middle of an operation
    if (reason === "backdropClick") {
      return;
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown={false}
      >
        <Box sx={style}>
          <IconButton
            color="#D0D0D0"
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <Close />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            {headerText}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {content}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
