import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  TextField,
  LinearProgress,
  Autocomplete,
  CircularProgress,
  Divider,
} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import DragAndDrop from "./DragAndDrop/DragDropBox";
import Loader from "./loader";
import axiosServices from "../utils/axiosServices";

function UploadCodeBase({
  showDragAndDrop,
  handleLater,
  setIsModalOpen,
  isGithubConnected,
  isGithubConnecting,
  handleGithubConnect,
  validateGithubConnection,
  repositories,
  selectedRepo,
  setSelectedRepo,
  handleFileSelected,
  handleFileDelete,
  selectedProjectId,
  getAllProjects,
  setShowUploadModal,
  uploadedFileName,
  setUploadedFileName,
  onClose,
}) {
  const [bufferedFile, setBufferedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [draggedFile, setDraggedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState(null);

  useEffect(() => {
    if (showDragAndDrop) {
      validateGithubConnection();
    }
  }, [showDragAndDrop, validateGithubConnection]);

  // Clear states when modal closes
  useEffect(() => {
    if (!showDragAndDrop) {
      setBufferedFile(null);
      setDraggedFile(null);
      setSelectedFileName(null);
      setSelectedRepo(null);
      setUploadedFileName?.("");
      handleFileDelete?.();
    }
  }, [showDragAndDrop, setSelectedRepo, setUploadedFileName, handleFileDelete]);

  console.info({ repositories });

  const handleRepoSelection = (event, newValue) => {
    console.info({ newValue });

    /**NEW VALUE STRUCTURE
    {
    "name": "blog-it-backend",
    "content": {
        "id": 920239531,
        "node_id": "R_kgDONtm9qw",
        "name": "blog-it-backend",
        "full_name": "Jothika2411/blog-it-backend",
        "private": false,
        "owner": {
            "login": "Jothika2411",
            "id": 95008786,
            "node_id": "U_kgDOBam4Eg",
            "avatar_url": "https://avatars.githubusercontent.com/u/95008786?v=4",
            "gravatar_id": "",
            "url": "https://api.github.com/users/Jothika2411",
            "html_url": "https://github.com/Jothika2411",
            "followers_url": "https://api.github.com/users/Jothika2411/followers",
            "following_url": "https://api.github.com/users/Jothika2411/following{/other_user}",
            "gists_url": "https://api.github.com/users/Jothika2411/gists{/gist_id}",
            "starred_url": "https://api.github.com/users/Jothika2411/starred{/owner}{/repo}",
            "subscriptions_url": "https://api.github.com/users/Jothika2411/subscriptions",
            "organizations_url": "https://api.github.com/users/Jothika2411/orgs",
            "repos_url": "https://api.github.com/users/Jothika2411/repos",
            "events_url": "https://api.github.com/users/Jothika2411/events{/privacy}",
            "received_events_url": "https://api.github.com/users/Jothika2411/received_events",
            "type": "User",
            "user_view_type": "public",
            "site_admin": false
        },
        "html_url": "https://github.com/Jothika2411/blog-it-backend",
        "description": "The backend is a Node.js application using Express and Apollo Server to provide a GraphQL API. It connects to a MongoDB database using Mongoose for data modeling. The API includes authentication features, allowing users to sign up and sign in, and provides endpoints for managing blog posts. ",
        "fork": false,
        "url": "https://api.github.com/repos/Jothika2411/blog-it-backend",
        "forks_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/forks",
        "keys_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/keys{/key_id}",
        "collaborators_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/collaborators{/collaborator}",
        "teams_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/teams",
        "hooks_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/hooks",
        "issue_events_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/issues/events{/number}",
        "events_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/events",
        "assignees_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/assignees{/user}",
        "branches_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/branches{/branch}",
        "tags_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/tags",
        "blobs_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/git/blobs{/sha}",
        "git_tags_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/git/tags{/sha}",
        "git_refs_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/git/refs{/sha}",
        "trees_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/git/trees{/sha}",
        "statuses_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/statuses/{sha}",
        "languages_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/languages",
        "stargazers_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/stargazers",
        "contributors_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/contributors",
        "subscribers_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/subscribers",
        "subscription_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/subscription",
        "commits_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/commits{/sha}",
        "git_commits_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/git/commits{/sha}",
        "comments_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/comments{/number}",
        "issue_comment_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/issues/comments{/number}",
        "contents_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/contents/{+path}",
        "compare_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/compare/{base}...{head}",
        "merges_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/merges",
        "archive_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/{archive_format}{/ref}",
        "downloads_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/downloads",
        "issues_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/issues{/number}",
        "pulls_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/pulls{/number}",
        "milestones_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/milestones{/number}",
        "notifications_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/notifications{?since,all,participating}",
        "labels_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/labels{/name}",
        "releases_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/releases{/id}",
        "deployments_url": "https://api.github.com/repos/Jothika2411/blog-it-backend/deployments",
        "created_at": "2025-01-21T19:59:10Z",
        "updated_at": "2025-01-22T06:53:02Z",
        "pushed_at": "2025-01-22T06:52:58Z",
        "git_url": "git://github.com/Jothika2411/blog-it-backend.git",
        "ssh_url": "git@github.com:Jothika2411/blog-it-backend.git",
        "clone_url": "https://github.com/Jothika2411/blog-it-backend.git",
        "svn_url": "https://github.com/Jothika2411/blog-it-backend",
        "homepage": null,
        "size": 36135,
        "stargazers_count": 0,
        "watchers_count": 0,
        "language": "TypeScript",
        "has_issues": true,
        "has_projects": true,
        "has_downloads": true,
        "has_wiki": true,
        "has_pages": false,
        "has_discussions": false,
        "forks_count": 0,
        "mirror_url": null,
        "archived": false,
        "disabled": false,
        "open_issues_count": 0,
        "license": null,
        "allow_forking": true,
        "is_template": false,
        "web_commit_signoff_required": false,
        "topics": [],
        "visibility": "public",
        "forks": 0,
        "open_issues": 0,
        "watchers": 0,
        "default_branch": "main",
        "permissions": {
            "admin": true,
            "maintain": true,
            "push": true,
            "triage": true,
            "pull": true
        }
        }
    }
    //  */
    if (!newValue) return;

    // here we need to download the repository zip file
    // and then upload it to the server

    const file = {
      name: newValue.name,
      content: newValue,
    };

    setBufferedFile(file);
    setSelectedRepo(newValue.html_url);
    setUploadedFileName?.(newValue.name);
    handleFileSelected(file);
    console.log("Repository selected:", file);
  };

  const handleDragAndDrop = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      setDraggedFile(file);
      setSelectedFileName(file.name);
      setUploadedFileName?.(file.name);
      handleFileSelected?.(file);
    }
  };

  const handleFileRemove = () => {
    setDraggedFile(null);
    setSelectedFileName(null);
    setUploadedFileName?.("");
    handleFileDelete?.();
  };

  const handleUploadSuccess = async (response) => {
    console.log("Upload success:", response);
    // Close both modals
    if (onClose) {
      onClose(); // This closes the upload modal
    }
    // Find and close the parent AddProject modal
    setIsModalOpen(false); // Make sure this state setter is passed from parent
    setShowUploadModal(false);
    getAllProjects();
    handleFileDelete();
    setDraggedFile(null);
  };

  const handleUploadError = (error) => {
    console.error("Upload error:", error);
    // Handle error but keep modals open
  };

  const handleGithubConnectWithLoading = async () => {
    await handleGithubConnect();
    // Note: We don't need to setIsGithubConnecting(false) here
    // as the component will re-render when redirected back
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      if (!selectedProjectId) {
        console.error("Project ID is missing");
        return;
      }

      if (!bufferedFile && !draggedFile) {
        console.error("No file selected");
        return;
      }

      setIsUploading(true);

      const formData = new FormData();
      formData.append("project_id", selectedProjectId);

      if (draggedFile) {
        // If file was uploaded via drag and drop
        formData.append("file", draggedFile);
        console.log("Sending dragged file:", draggedFile.name);
      } else {
        const { full_name, default_branch } = bufferedFile.content;
        const [owner, repo] = full_name.split("/");

        const repoZipUrl = `https://api.github.com/repos/${owner}/${repo}/zipball/${default_branch}`;
        formData.append("repositoryUrl", repoZipUrl);
      }

      const response = await axiosServices.post(
        "/projects/uploadcodebase",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
          },
        }
      );

      handleUploadSuccess(response);
    } catch (error) {
      handleUploadError(error);
    } finally {
      setIsUploading(false);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={showDragAndDrop}
      onClose={() => handleLater(() => setIsModalOpen(false))}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          bgcolor: "white",
          p: 4,
          borderRadius: 2,
          boxShadow: 24,
          width: "500px",
          position: "relative",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {isLoading && <Loader open={isLoading} />}

        <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
          Upload Your Codebase
        </Typography>

        <Box sx={{ mb: 4 }}>
          <Button
            variant={isGithubConnected ? "contained" : "outlined"}
            color={isGithubConnected ? "success" : "primary"}
            onClick={handleGithubConnectWithLoading}
            disabled={isGithubConnected || isGithubConnecting}
            fullWidth
            startIcon={<GitHubIcon />}
            sx={{
              mb: 2,
              py: 1.5,
              position: "relative",
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: 500,
              "&.Mui-disabled": {
                backgroundColor: isGithubConnected ? "#2e7d32" : undefined,
                color: isGithubConnected ? "#fff" : undefined,
              },
            }}
          >
            {isGithubConnecting ? (
              <CircularProgress size={20} color="inherit" />
            ) : isGithubConnected ? (
              "Connected to GitHub"
            ) : (
              "Connect to GitHub"
            )}
          </Button>

          {isGithubConnected && repositories?.length > 0 && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
                Select Repository
              </Typography>
              <Autocomplete
                fullWidth
                options={repositories}
                getOptionLabel={(repo) => repo.full_name}
                value={
                  repositories.find((repo) => repo.html_url === selectedRepo) ||
                  null
                }
                onChange={handleRepoSelection}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search repositories..."
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: "primary.main",
                        },
                      },
                    }}
                  />
                )}
              />
            </Box>
          )}
        </Box>

        <Divider sx={{ my: 3 }}>
          <Typography variant="body2" color="text.secondary">
            OR
          </Typography>
        </Divider>

        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
          Upload Directly
        </Typography>

        {selectedFileName ? (
          <Box
            sx={{
              p: 2,
              border: "1px solid #e0e0e0",
              borderRadius: 1,
              bgcolor: "#f5f5f5",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="body2" color="text.secondary">
                Selected File:
              </Typography>
              <Typography
                variant="body1"
                color="primary"
                sx={{ fontWeight: 500 }}
              >
                {selectedFileName}
              </Typography>
              {draggedFile && (
                <Typography variant="caption" color="text.secondary">
                  Size: {(draggedFile.size / 1024).toFixed(2)} KB
                </Typography>
              )}
            </Box>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={handleFileRemove}
            >
              Remove
            </Button>
          </Box>
        ) : (
          <DragAndDrop
            onFileSelected={handleDragAndDrop}
            uploadedFileName={uploadedFileName}
            required
            id="fileInput"
            disabled={isLoading}
          />
        )}

        {isUploading && (
          <Box sx={{ width: "100%", mt: 2 }}>
            <LinearProgress variant="determinate" value={uploadProgress} />
            <Typography
              variant="caption"
              display="block"
              textAlign="center"
              mt={1}
            >
              Uploading: {uploadProgress}%
            </Typography>
          </Box>
        )}

        <Box display="flex" justifyContent="flex-end" mt={3} gap={1}>
          <Button
            variant="outlined"
            onClick={() => handleLater(() => setIsModalOpen(false))}
            disabled={isUploading}
            sx={{ textTransform: "none" }}
          >
            Skip for now
          </Button>
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={(!draggedFile && !selectedRepo) || isUploading}
            sx={{ textTransform: "none" }}
          >
            {isUploading ? `Uploading ${uploadProgress}%` : "Submit"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default UploadCodeBase;
