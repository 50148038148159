import * as Yup from "yup";
import ERROR_MESSAGES from "../constants/errorMessages";

const projectValidationSchema = Yup.object().shape({
  projectName: Yup.string().required("Project Name is required"),
  description: Yup.string().required("Description is required"),
  collaborators: Yup.array()
    .of(
      Yup.object().shape({
        email: Yup.string()
          .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            ERROR_MESSAGES.INVALID_EMAIL
          )
          .nullable(),
      })
    )
    .nullable()
    .default([])
    .notRequired(),
});

export default projectValidationSchema;
