import axiosServices from "../utils/axiosServices";

class GitHubService {
  constructor() {
    this.clientId = process.env.REACT_APP_GITHUB_CLIENT_ID;
    this.redirectUri =
      process.env.REACT_APP_GITHUB_REDIRECT_URI ||
      `${window.location.origin}/projects`;
  }

  async validateConnection(email) {
    try {
      const response = await axiosServices.get(
        "/users/github/validate-github-token",
        {
          params: { email },
        }
      );
      return {
        isConnected: !!response.data.token,
        token: response.data.token,
      };
    } catch (error) {
      console.error("Error validating GitHub connection:", error);
      return { isConnected: false };
    }
  }

  async fetchRepositories(email) {
    try {
      const response = await axiosServices.get("/users/github/repositories", {
        params: { email },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching repositories:", error);
      throw error;
    }
  }

  async exchangeCode(code, email) {
    try {
      const response = await axiosServices.post("/users/github/exchange-code", {
        code,
        email,
      });
      return response.data;
    } catch (error) {
      console.error("Error exchanging GitHub code:", error);
      throw error;
    }
  }

  initiateConnection() {
    const githubUrl = `https://github.com/login/oauth/authorize?client_id=${this.clientId}&redirect_uri=${this.redirectUri}&scope=repo`;
    window.location.href = githubUrl;
  }
}

export default new GitHubService();
